@charset 'utf-8';

.MyProjects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--third);
  padding-bottom: 150px;
}

/* svg {
  background-color: black;
} */

.MyProjects .title {
  /* margin-left: 80px; */
  font-family: "Calistoga", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 45px;
  color: var(--primary);
}

.project {
  margin-top: 80px;
  border-radius: 20px;
  background-color: var(--secondary);
  display: flex;
}

.project img {
  max-width: 600px;
  margin: 50px 30px;
  border-radius: 15px;
}

.project-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
  width: 500px;
}

.project-title {
  margin: 0;
  font-family: "Calistoga", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  color: var(--primary);
}

.project-description {
  margin-top: 20px;
  font-size: 16px;
  color: var(--primary);
}

.project-button {
  display: flex;
  justify-content: flex-end;
}

.project-button a {
  text-decoration: none;
  color: var(--primary);
}

.project-profile button {
  /* padding: 12px 24px;
  margin-right: 15px; */
  margin-top: 30px;
  background-color: var(--buttons);
  color: var(--primary);
  border: none;
  border-radius: 30px;
  font-weight: 600;
  font-size: 15px;
}

.stack {
  margin-top: 22px;
}

.stack-name {
  padding: 10px 15px;
  margin-right: 15px;
  border-radius: 15px;
  /* box-shadow: 2px 6px grey; */
  background-color: var(--third);
  color: var(--primary);
  font-family: "Calistoga", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 9.5px;
  text-transform: uppercase;
  font-family: Arial;
  letter-spacing: 0.8px;
}

/* Project button effect */

  
.effect {
  border-radius: 30px;
  font-weight: 600;
  font-size: 14 px;
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  /* background-color: - add your own background-color */
  background-color: var(--buttons);
  color: var(--primary);

  padding: 10px 0px;
  width: 150px;
  overflow: hidden;
}

/* effect-1 styles */


.effect.effect-1 {
  transition: all 0.2s linear 0s;
  
  &:before {
    font-family: "FontAwesome";
    content: "\f061";
    font-size: 15px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 40px;
    transition: all 0.2s linear 0s;
  }
  
  &:hover {
    text-indent: -20px;
    
    &:before {
      opacity: 1;
      text-indent: 0px;
    }
  }
}

@media screen and (max-width: 1200px) {


  .project{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project img {
    width: 100%;
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .project-profile {
    max-width: 90%;
    margin-top: 20px;
  }

  .project-profile button {
    font-size: 13px;
    margin: 10px 0 20px 0;
  }

  .MyProjects .title { 
    margin: 0;
  }

}

@media screen and (max-width: 600px) {
  
  .MyProjets .container {
    display: flex;
    align-items: center;
  }
  .project {
    /* width: 90%; */
  }

  .project-profile {
    width: 100%;
    padding-left: 30px;
  }
}






