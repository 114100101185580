:root {
  /* Background About me & Card font */
  --primary: #232946;
  /* Headline & Cards background */
  --secondary: #fffffe;
  /* Background  Projects */
  --third: #d4d8f0;
  /* Buttons */ 
  --buttons: #eebbc3;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
