.AboutMe {
  display : flex;
  grid-column-gap: 10rem;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding: 0 200px;
}

/* .AboutMe .container {
  display: flex;
  background-color: var(--third);
} */

.SumUp {
  max-width: 700px;
  /* background-color: #FFF9F0; */
  border: none;
  /* padding: 80px 30px;
  border-radius: 100px 70px 100px 50px;
  box-shadow: 2px 6px grey; */
}

.title {
  font-family: "Calistoga", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 45px;
  color: var(--secondary);
}

.description {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  margin-top: 10px;
  color: #b8c1ec;
}

.Profile {
  background-color: var(--third);
  border-radius: 25px;
}

.photo img {
  height: 300px;
}

.info {
  height: 5rem;
  font-size: 15px;
  padding: 15px;
  color: var(--secondary);
  font-weigth: 800;
}

/* Polaroid effect */


* {
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  padding: 0 2rem;
  text-align: center;
}
.polaroid {
  background: #fff;
  padding: 1rem;
  box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
  
}
.polaroid > img{
  max-width: 100%;
  height: auto;
}
.caption {
  height: 40px;
  font-size: 1rem;
  text-align: center;
  line-height: 2em;
}
.item {
  display: inline-block;
  margin-top: 2rem;
  filter: grayscale(100%);
}
.item .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) {
  transform: scale(0.8, 0.8) rotate(5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) .polaroid:before {
  transform: rotate(6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+2) {
  transform: scale(0.8, 0.8) rotate(-5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+2) .polaroid:before {
  transform: rotate(-6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+4) {
  transform: scale(0.8, 0.8) rotate(3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+4) .polaroid:before {
  transform: rotate(4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:nth-of-type(4n+3) {
  transform: scale(0.8, 0.8) rotate(-3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+3) .polaroid:before {
  transform: rotate(-4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:hover {
  filter: none;
  transform: scale(1, 1) rotate(0deg) !important;
  transition: all 0.35s;
}
.item:hover .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transform: rotate(0deg);
  height: 90%;
  width: 90%;
  bottom: 0%;
  right: 5%;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.2);
  transition: all 0.35s;
}

@media screen and (max-width: 1000px) {

  .AboutMe {
    padding: 0 50px;
  }
  .wrapper {
    display: none;
  }
  .title {
    font-size: 28px;
  }

  .description {
    font-size: 1.1rem;
    line-height: 1.3;
  }
}

@media screen and (max-width: 700px) {
  .description {
    font-size: 0.9rem;
    line-height: 1.3;
  }
}

