.Navbar {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
}

.Navbar button {
  padding: 10px 20px;
  margin-right: 80px;
  background-color: var(--buttons);
  border-radius: 30px;
  border: none;
  color: var(--secondary);
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  &:hover {
    cursor: pointer;
  }
  
}

.name {
  margin-left: 80px;
  font-family: "Calistoga", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 45px;
  color: var(--secondary);
}

@media screen and (max-width: 1200px) {
  .name {
    font-size: 25px
  }

  .Navbar button {
    font-size: 15px;
  }
}


@media screen and (max-width: 500px) {
  .name {
    font-size: 21px
  }

  .Navbar button {
    font-size: 11px;
  }
}
